.payment__forn {
  width: 100%;
}

.payment__total-area {
  background-color: var(--color-dark-grey);
  text-align: center;
  padding: 24px;
  border-radius: 4px;
}

/* TODO: update rules */
.payment__input--extra-large {
  font-family: "AmsiProCond-Black", sans-serif;
  font-size: 40px;
  text-align: center;
  width: 100%;
  background-color: var(--color-white);
  color: var(--color-dark-grey);
  padding: 0;
  border: none;
}

.payment__input--extra-large:disabled {
  background-color: var(--color-dark-grey);
  color: var(--color-white);
}

.payment__details-area {
  background-color: var(--color-light-grey);
  padding: 24px;
  border-radius: 4px;
}

.payment__label {
  display: block;
  font-size: 12px;
  color: var(--color-medium-grey);
  margin-bottom: 0.2rem;
}

/* TODO: update rules */
.payment__input--small {
  font-size: 14px;
  background-color: var(--color-white);
  color: var(--color-dark-grey);
  width: 100%;
  margin: 0 0 0.5rem 0;
  padding: 0.2rem 0.1rem;
  border: 1px solid var(--color-dark-grey);
}

.payment__input--small:disabled {
  background-color: var(--color-light-grey);
  color: var(--color-dark-grey);
  border: none;
}

.payment__details-area input:last-child {
  margin: 0;
}

.payment__repayments-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  padding: 0 24px;
}

.payment__repayment-input--date:disabled {
  font-size: 12px;
  background-color: var(--color-white);
  width: 100%;
  color: var(--color-medium-grey);
  margin: 0 0 0.2rem 0;
  padding: 0;
  border: none;
}

.payment__repayment-input--amount:disabled {
  font-family: "Amasis", serif;
  font-size: 16px;
  background-color: var(--color-white);
  width: 100%;
  color: var(--color-dark-grey);
  margin: 0;
  padding: 0;
  border: none;
}

@media only screen and (max-width: 1200px) {
  .payment__total-area {
    padding: 16px 0;
  }

  .payment__repayments-area {
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    gap: 16px;
    margin-bottom: 32px;
  }

  .payment__label {
    font-size: 14px;
  }

  .payment__input--small:disabled {
    font-size: 16px;
  }

  .payment__repayment-input--date:disabled {
    font-size: 14px;
  }

  .payment__repayment-input--amount:disabled {
    font-size: 18px;
  }

  .payment__form p:nth-of-type(2) {
    text-align: left;
    margin-left: 24px;
  }
}
