.stripe-subscription-payment-element {
  display: none;
  height: 0px;
  padding-top: 20px;
}

.stripe-subscription-payment-loading {
  display: flex;
  height: 100%;
}
.stripe-subscription-payment-debit-card-disclaimer {
  display: block;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 24px;
  margin-bottom: 12px;
}

.stripe-subscription-payment-container {
  position: relative;
}

.stripe-subscription-payment-form {
  position: relative;
  min-height: 300px;
  margin-bottom: 20px;
}
