.radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
  
.radio {
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
  outline-color: "#bce5f8";
  outline-offset: 1px;
  padding: 0;
}
  
.radio::before {
  background-color: white;
  border: 2px solid #028dd0;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.4rem white;
  content: ' ';
  display: block;
  height: 32px;
  width: 32px;
}
  
.radio:checked::before {
  background-color: #028dd0;
  border-color: #028dd0;
}
  
.radio:hover::before {
  border: 2px solid #0aa5e8;
}
  
.radio:checked:hover::before {
  background-color: #0aa5e8;
  border-color: #0aa5e8;
}
  
.label {
  color: #424554;
  font-family: 'Roboto';
  font-size: 1rem;
  line-height: 26px;
}
