.radio-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.radio {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  display: inline-block;
  margin-left: -3px;
}

.radio-label {
  padding-left: 5px;
}
