.modal-container {
  max-height: 879px !important;
  padding-left: 5%;
  padding-right: 5%;
  overflow: auto;
}

.modal-title {
  font-size: 30px;
  color: var(--color-dark-grey);
}

.modal-body-container {
  height: auto;
  min-height: 50dvh;
}

.modal-navigation-text {
  font-size: 12px;
  background-color: #f9f1fa;
  border: 1px solid rgba(169, 91, 166, 0.2);
  text-align: center;
  border-radius: 10px;
  width: 100px;
  height: 22px;
  margin-bottom: 20px;
}

.modal-close-button {
  min-width: 30px;
  min-height: 30px;
  margin-left: auto;
  border: 1px solid rgba(169, 91, 166, 0.2);
  border-radius: 50%;
  background: url("assets/images/modal-close-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.modal-close-button:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: #f7f8fe;
}

.button-container {
  margin-top: auto;
  padding-top: 15px;
}
