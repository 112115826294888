.dropdown-search-field-container {
  position: relative;
  z-index: 1;
}

.dropdown-search-field-row {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  padding: 0;
  top: 54px;
  max-height: 160px;
  min-height: 40px;
  margin-top: 5px;
  overflow: auto;
  list-style-type: none;
  background: var(--color-white);
  border: 1px var(--input-border-color) solid;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.35);
}

.dropdown-search-field-row-hidden {
  display: none;
}

.dropdown-search-field-row-hidden-loading-item {
  padding-left: 8px;
}

.dropdown-search-field-default-title {
  padding-left: 8px;
}
