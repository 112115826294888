.wrapper {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  padding: 0;
}
  
.radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}
  
.legend {
  color: "#424554";
  font-family: 'Roboto';
  font-size: 1rem;
  line-height: 26px;
  margin-bottom: 1rem;
}
