.sticky-button {
  bottom: 0px;
  background-color: white;
  border-bottom: none;
  position: sticky;
}

.sticky-button::after,
.sticky-button::before {
  content: "";
  position: absolute;
  background-color: white;
  z-index: -1;
}

.sticky-button::after {
  bottom: -20px;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
}

.sticky-button::before {
  top: 0;
  left: -20px;
  right: -20px;
  width: calc(100% + 36px);
  height: calc(100% + 36px);
}
