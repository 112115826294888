@import "styles/components/RadioButton";
@import "styles/components/Button";
@import "styles/components/Drawdown";
@import "styles/components/Form";
@import "styles/components/Icon";
@import "styles/components/Splash";
@import "styles/components/Label";

* {
  box-sizing: border-box;
}

/* Disables the up/down arrow on number inpput fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
}

:root {
  --color-primary: #017dba;
  --color-primary-hover: #009bde;
  --color-primary-disabled: #97d6f4;
  --color-purple: #75227d;
  --color-white: #ffffff;
  --color-dark-grey: #424554;
  --color-medium-grey: #737480;
  --error-text-color: #ce1025;
  --success-text-color: #34b576;
  --input-border-color: #c9cbd9;
  --input-focus-color: #bce5f8;
  --list-item-hover-color: #eef9ff;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-dark-grey);
}

h2 {
  font-family: "Amasis Bold", serif;
  font-size: 20px;
}

.footer--links-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.text--center {
  text-align: center;
}

.text-color--red {
  color: var(--error-text-color);
}

.text-color--purple {
  color: var(--color-purple);
}

.text-color--white {
  color: var(--color-white);
}

.font-roboto-normal {
  font-family: "Roboto", sans-serif !important;
}

.font-roboto--bold {
  font-family: "Roboto Bold", sans-serif;
}

.font-roboto-light {
  font-family: "Roboto Light", sans-serif !important;
}

.font-amasis--normal {
  font-family: "Amasis", serif !important;
}

.mv--large {
  margin: 56px 0;
}

.mt--small {
  margin-top: 16px;
}

.mt-large {
  margin-top: 56px;
}

.mb--medium {
  margin-bottom: 32px;
}

.faint-divider {
  border-top: 1px solid #e2e8f0;
  opacity: 1;
}

.display-flex {
  display: flex;
  align-items: center;
}

@font-face {
  font-family: "Amasis";
  font-weight: normal;
  src: url("./assets/fonts/AmasisMTStd.ttf");
}

@font-face {
  font-family: "Amasis Bold";
  font-weight: bold;
  src: url("./assets/fonts/AmasisMTStd-bold.ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Light";
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  font-weight: bold;
  src: url("./assets/fonts/Roboto-bold.ttf");
}

@font-face {
  font-family: "AmsiProCond-Black";
  font-weight: bold;
  src: url("./assets/fonts/AmsiProCond-Black.ttf");
}
