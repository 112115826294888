.label-group-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 8px;
}

.label-title {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-medium-grey)
}

.label-title-dark {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-dark-grey)
}

.label-icon-container {
  padding-left: 3px;
  display: inline-flex;
  align-items: center;
}

.max-width {
  width: 100%;
}
