.custom-payment-splash {
  max-width: 750px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start;
  text-align: center;
  padding-bottom: 20px;
}

.custom-payment-splash-group-icon {
  align-self: center;
  margin-bottom: 5px;
}

.custom-payment-splash-header {
  font-family: "Roboto Light", sans-serif;
  font-size: 24px;
  color: #0079b3;
  margin-bottom: 16px;
}

.custom-payment-splash-subheading {
  font-family: "Roboto Bold", sans-serif;
  font-size: 20px;
  margin-bottom: 42px;
}

.custom-payment-splash-payment-amount {
  margin-bottom: auto;
}

.custom-payment-newbo-button {
  margin-top: 16px;
  margin-bottom: 16px;
}

.custom-payment-exbo-button {
  background: none;
  border: none;
  color: #0079b3;
  text-decoration: underline;
  cursor: pointer;
}

.custom-payment-skeleton-loader {
  width: 200px;
  height: 24px;
  padding: 5px;
  border-radius: 4px;
  background-color: lightgrey;
  animation: flash 1s infinite ease-in-out;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
