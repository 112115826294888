.page-number-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.page-number-title-circle {
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #8F448A;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.page-number-title-text {
  font-family: "Roboto Bold", sans-serif;
  font-size: 20px;
}
