.dynamic-label-input {
  position: relative;
  display: flex;
  flex-grow: 1;
  border-radius: 4px;
  border: 1px #9396a7 solid;
}

.dynamic-label-input-main {
  position: relative;
  width: 100%;
  height: 54px;
  display: inline-block;
}

.dynamic-label-input input {
  top: 0;
  left: 0;
  position: absolute;
  height: 54px;
  width: 100%;
  padding: 3px 16px;
  width: 100%;
  line-height: 16px;
  font: 16px/16px "Roboto Bold";
  border-radius: 3px;
  border: none;
  background-color: transparent;
}

.dynamic-label-input input.dynamic-label-input-icon-padding {
  padding-right: 40px;
}

.dynamic-label-input label {
  position: absolute;
  top: 15px;
  left: 16px;
  font-size: 16px;
  transition: font-size 0.2s;
}

.main-input:focus-within .dynamic-label-input label,
.dynamic-label-input label.small {
  position: absolute;
  top: 4px;
  line-height: 12px;
  left: 16px;
  font-size: 12px;
  color: #424554;
}

.main-input:focus-within label,
.dynamic-label-input label.small {
  position: absolute;
  top: 4px;
  line-height: 12px;
  left: 16px;
  font-size: 12px;
}

.dynamic-label-input .side-label {
  min-width: 47px;
  min-height: 54px;
  background-color: var(--color-light-grey);
  border-right: 1px solid #9396a7;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamic-label-input input.error {
  border: 1px solid var(--error-text-color);
}

.dynamic-label-input-error {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--error-text-color);
  top: 54px;
  gap: 5px;
  font-size: 12px;
  margin-bottom: 2px;
}

.dynamic-label-input-info-message {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: black;
  top: 54px;
  gap: 5px;
  font-size: 12px;
  margin-bottom: 2px;
}

.dynamic-label-input-error-icon {
  display: inline-block;
  columns: red;
  height: 1em;
  line-height: 1em;
  vertical-align: -0.2em;
}

.dynamic-label-input-message-icon {
  display: inline-block;
  color: black;
  height: 1em;
  line-height: 1em;
  vertical-align: -0.2em;
}
