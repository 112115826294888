input[type="submit"] {
  font-size: 16px;
}

.form__label {
  display: flex;
  color: var(--color-medium-grey);
  margin-bottom: 8px;
}

.form__input--group {
  position: relative !important;
  display: block;
  height: 48px;
  width: 100%;
}

.form__input {
  display: block;
  color: var(--color-medium-grey);
  padding-left: 8px;
  height: 48px;
  width: 97%;
  font-size: 16px;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
}

.form__input:focus {
  box-shadow: 0 0 0 1px var(--color-dark-grey), 0 0 0 3px var(--input-focus-color);
  outline: none;
}

.form__input--phone {
  width: calc(100% - 56px) !important;
  margin-left: 56px;
  border-radius: 0 4px 4px 0;
}

.form__input--unit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 57px;
  background-color: var(--color-light-grey);
  box-shadow: 0 0 0 1px var(--input-border-color) inset;
  line-height: 100%;
  white-space: nowrap;
  border-radius: 4px 0 0 4px;
}

.form-fields-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.form-fields-container :last-child {
  margin-bottom: 0px;
}

@media only screen and (max-width: 1200px) {
  .form__input {
    width: 97%;
  }
}
