
.shareholder-input-container {
  display: flex;
  flex-direction: column;

}

.shareholder-input-header {
  height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  white-space: nowrap;
}

.shareholder-input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
  margin-bottom: 16px;
}

.shareholder-input-action-button {
  display: inline-block;
  margin-left: 15px;
  background-color: transparent;
  color: #0079b3;
  border: none;
  user-select: none;
}

.shareholder-input-action-button:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .shareholder-input-row {
    flex-direction: column;
  }
}
