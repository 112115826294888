.icon-info-text-container {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.icon-info-text {
  font-size: 12px;
  font-family: "Roboto";
  color: #424554;
  padding-left: 5px;
}

.icon-info-text-link {
  padding-left: 3px;
}

.icon-info-link-text-button {
  font-size: 12px;
  font-family: "Roboto";
  padding: 3px !important;
}
