.form-phone-number-input {
    border-radius: 0 4px 4px 0;
    display: block;
    color: var(--color-medium-grey);
    padding-left: 8px;
    height: 48px;
    width: 97%;
    font-size: 16px;
    border: 1px solid var(--input-border-color);
}

.form-phone-number-input:focus {
    box-shadow: 0 0 0 1px var(--color-dark-grey), 0 0 0 3px var(--input-focus-color);
    outline: none;
}

.international-dial-code {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 46px;
    min-width: 47px;
    background-color: var(--color-light-grey);
    box-shadow: 0 -1px 0 0 var(--input-border-color), -1px 0 0 0 var(--input-border-color), 0 1px 0 0 var(--input-border-color);
    line-height: 100%;
    white-space: nowrap;
    border-radius: 4px 0 0 4px;
}
