.eligibility-loading-container {
  display: flex;
  height: 100%;
}

.eligibility-title-description-inner-bold {
  font-size: 16px;
  font-family: "Roboto bold";
  color: #424554;
}

.eligibility-form-fields-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.eligibility-form-fields-container input {
  margin-bottom: 0px;
}
