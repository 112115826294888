.dialog {
  --toggle-border-color: #0079b3;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  display: flex;
  flex: 1;
  height: 100dvh;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.modal {
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 50vw;
  max-width: 670px;
  overflow: auto;
  max-height: calc(100% - 4rem);
}

.modal-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 1rem 1rem 1rem 1rem;
}
