.splash-template-side-content {
  padding-top: 52px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-items: flex-end;
  height: 100%;
  color: #ffffff;
}

.splash-template-side-content ul {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  gap: 32px
}

.splash-template-header {
  font-family: "Amasis Bold", serif;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 16px;
}

@media only screen and (max-width: 1200px)  { 
 
  .splash-template-side-content {
    padding: 32px 16px;
  }
}

.splash-template-description {
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 42px;
}

.splash-template-side-content li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  gap: 16px;
}

.splash-template-side-content li > span {
  min-width: 90px;
}

.splash-template-icon {
  height: 14px;
}

.splash-template-icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

ul li:nth-child(1) .splash-template-icon-circle {
  background-color: #e8c9eb;
}

ul li:nth-child(2) .splash-template-icon-circle {
  background-color: #97d6f4;
}

ul li:nth-child(3) .splash-template-icon-circle {
  background-color: #ffd2bc;
}

.splash-template-credit-check-disclaimer {
  color: #eff0f5;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 100px;
}
