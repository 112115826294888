@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;700&display=swap");

.repayment-pills {
  max-width: 375px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
  margin-right: auto;
  margin-top: 32px;
}

.repayment-pill {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 407px) {
  .repayment-pills {
    margin-top: 36px;
    flex-direction: column;
    gap: 16px;
  }

  .repayment-pill {
    margin-right: 0;
    flex-direction: row;
    gap: 8px;
  }
}

.repayment-pill-body {
  width: fit-content;
  max-width: 130px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  background-color: #97d6f4;
  color: #424554;
  padding: 8px 10px;
}

.repayment-pill-paid {
  background-color: #70d8a6;
}

.repayment-pill-centred {
  flex-direction: column;
}

.repayment-pill-amount {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;
}

.repayment-pill-index {
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: "Roboto";
}

.repayment-pill-date {
  font-family: "Inter";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  text-align: center;
  color: white;
  margin-top: 8px;
  white-space: nowrap;
}

.repayment-pill-date-bold {
  font-weight: 700;
}

.repayment-pill-date-dark {
  color: black;
  margin-top: 8px;
  font-size: 18px;
  font-family: "Roboto Light";
}
