.payment-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: fit-content;
  background: #75227d;
  color: #ffffff;
  border-radius: 4px;
}

.payment-amount {
  font-family: "AmsiProCond-Black", sans-serif;
  font-size: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 100%;
}

.payment-reference {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 16px;
}
