.address-search-container {
  padding-bottom: 20px;
}

.address-fields-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.manual-button-container {
  padding-top: 15px;
}

.form-container {
  min-height: 50dvh;
  flex-direction: column;
  display: flex;
}

.select-date-container {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.horizontal-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.max-width {
  width: 100%;
}

.button-container {
  margin-top: auto;
  padding-top: 15px;
}

@media only screen and (max-width: 600px) {
  .address-fields-container .horizontal-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  #flat {
    width: 100%;
    /*margin-right: 0px;*/
  }

  #houseNameNumber {
    width: 100%;
  }
}
