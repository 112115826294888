.shareholders-input-list-container {
  min-height: calc(100% - 42px);
  padding-left: 0;
  margin: 0
}

.shareholders-form {
  flex-grow: 1;
}

.shareholders-page-description {
  display: block;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin: 24px 0;
}

.shareholders-submit-button-sticky {
  height: 82px;
  bottom: calc(-40px);
  background-color: var(--color-white);
  border-bottom: none;
  position: sticky;
}

.shareholders-page-description > a {
  color: #0079b3;
  text-decoration: none;
}

.shareholders-page-description > a:hover {
  text-decoration: underline;
}

.shareholders-page-description > a:visited {
  color: #0079b3;
}

@media only screen and (max-width: 1200px)  {
  .shareholders-submit-button-sticky {
    height: 78px;
    bottom: -36px;
  }
}

