.payment-template-side-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #ffffff;
  padding-top: 52px;
  font-size: 16px;
}

.payment-template-header {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 16px;
}

.payment-template-supplier-name {
  font-family: "Roboto Light";
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 16px;
}

.payment-template-payment-amount-bold {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.payment-template-payment-amount-normal {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}

.payment-template-horizontal-seperator {
  border: 1px solid #737480;
  width: 100%;
  margin: 52px 0;
}

.payment-template-invoice-ref {
  margin-top: 52px;
}

@media screen and (max-width: 1200px) {
  .payment-template-invoice-ref {
    margin-top: 42px;
  }
}

.payment-template-disclaimer {
  color: #eff0f5;
  font-size: 14px;
  margin-top: auto;
}

.payment-template-disclaimer a {
  color: inherit;
}

.base-template-main-content.confetti {
  background-image: url("assets/images/confetti.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1200px) {
  .payment-template-side-content {
    padding: 52px 16px 16px;
    height: fit-content;
  }
}
