.error-container {
  margin-top: 32px;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  max-width: 740px;
  padding: 16px;
  background-color: rgba(255, 0, 0, 0.05);
  border: 1px;
  border-style: solid;
  border-color: red;
  border-radius: 6px;
}

.error-title-container {
  display: flex;
}

.error-redirect-link {
  margin-left: auto;
}

.error-sub-container {
  display: flex;
  align-items: flex-start;
  padding: 16px;
}

.error-title {
  font-style: normal;
  font-size: 18px;
  font-family: "Roboto Bold";
  line-height: 20px;
  color: #424554;
  margin-bottom: 32px;
}

.error-icon-panel {
  margin-right: 10px;
}

.error-body-link {
  margin-left: 5px;
}
