.universal-payment-splash {
  max-width: 750px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start;
  text-align: center;
}

.universal-payment-splash-group-icon {
  align-self: center;
  margin-bottom: 5px;
  height: 32px;
}

.universal-payment-splash-amount-input {
  margin-bottom: 16px;
}

.universal-payment-splash-header {
  font-family: "Roboto Light", sans-serif;
  font-size: 24px;
  color: #0079B3;
  margin-bottom: 16px;
}

.universal-payment-splash-subheading {
  font-family: "Roboto Bold", sans-serif;
  font-size: 20px;
  margin-bottom: 42px;
}

.universal-payment-splash-form {
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: auto;
}

.universal-payment-splash-newbo-button {
  margin-top: 24px;
  margin-bottom: 16px;
}

.universal-payment-splash-exbo-button {
  background: none;
  border: none;
  color: #0079b3;
  text-decoration: underline;
  cursor: pointer;
}

.universal-payment-splash-exbo-sign-in.hidden {
  width: 0px;
  height: 24px;
  overflow: hidden;
}



.universal-payment-splash .label-title {
  color: var(--color-dark-grey);
}
