.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-input {
  display: none;
  vertical-align: bottom;
}

.checkbox-custom {
  display: inline-block;
  vertical-align: bottom;
  background-size: contain;
}

.subtitle {
  font-size: 14px;
  color: #424554;
}

.checkbox-input:checked + .checkbox-custom {
  background-image: url("assets/images/success-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  width: 25px;
  height: 25px;
}

.add-details-button {
  margin-left: auto;
  background-color: transparent;
  color: #0079b3;
  border: none;
}

.add-details-button:hover {
  text-decoration-line: underline;
}
