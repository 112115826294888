.anchor {
  display: inline-block;
  color: #0079b3;
  text-decoration: underline;
  cursor: pointer;
}

.anchor:hover {
  text-decoration: none;
}
