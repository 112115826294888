.no-company-found-title {
  font-family: "Roboto Bold";
  font-size: 16px;
  font-weight: 700;
  color: var(--color-dark-grey);
  line-height: 24px;
  padding-left: 16px;
  padding-top: 8px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-company-found-description {
  padding-left: 16px;
  padding-top: 8px;
  color: var(--color-medium-grey);
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  padding-bottom: 20px;
  padding-right: 16px;
}
