.btn {
  display: block;
  text-align: center;
  text-decoration: none;
  width: 20rem;
  margin: 0 auto;
  padding: 12px 0;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.btn--primary {
  background-color: var(--color-primary);
  width: 100%;
  color: var(--color-white);
  box-shadow: inset 0 0 0 2px var(--color-primary);
}

.btn--primary:hover {
  background-color: var(--color-primary-hover);
  text-decoration: underline;
  box-shadow: inset 0 0 0 2px var(--color-primary-hover);
}

.btn--primary:disabled {
  background-color: var(--color-primary-disabled);
  box-shadow: inset 0 0 0 1px var(--color-primary-disabled);
  cursor: not-allowed;
}

.btn--secondary {
  background-color: var(--color-white);
  width: 100%;
  color: var(--color-primary);
  box-shadow: inset 0 0 0 2px var(--color-primary);
}

.btn--secondary:hover {
  color: var(--color-primary-hover);
  box-shadow: inset 0 0 0 2px var(--color-primary-hover);
  text-decoration: underline;
}

.button-link {
  display: inline-block;
  color: #0079b3;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.1s ease-out;
  background: none;
  border: none;
}

.button-link:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  .btn {
    width: 100%;
  }
}
