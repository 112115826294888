.company-search-loading-container {
  min-height: 160px;
}

.company-search-loading-title {
  max-width: 300px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-family: "Roboto Bold";
  color: var(--color-dark-grey);
  padding-top: 8px;
  padding-left: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company-search-loading-spinner {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: icon-rotate;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  background: linear-gradient(var(--color-white), var(--color-white)) content-box,
    linear-gradient(to right, #028dd0 0%, #028dd0 65%, var(--color-white) 65%, var(--color-white) 100%) border-box;
  height: 32px;
  width: 32px;
  margin: auto;
  border: 5px solid transparent;
  border-radius: 50%;
  margin-top: 25px;
}

@keyframes icon-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
