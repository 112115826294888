.business-details-content {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.business-details-horizontal-grouping {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.business-details-radio-vertical-containers {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.radio-loss label::before {
  content: url("assets/images/red-arrow-icon.svg");
  padding-right: 4px;
}

.radio-profit label::before {
  content: url("assets/images/green-arrow-icon.svg");
  padding-right: 4px;
}
