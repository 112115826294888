.alert__icon {
  display: block;
  margin: 156px auto 48px auto;
}

.svg__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: auto 0;
}

.svg__icon--success {
  margin-left: 3px;
  fill: var(--success-text-color);
}

.svg__icon--fail {
  margin-left: 3px;
  fill: var(--error-text-color);
}

.svg__icon--search {
  width: 22px;
  height: 22px;
}

.svg__icon--close {
  width: 20px;
  height: 20px;
}
