.payment-submitted-container {
  width: 100%;
  padding: 16px;
  background-color: rgba(0, 255, 0, 0.05);
  border:1px;
  border-style: solid;
  border-color: green;
  border-radius: 6px;
  margin-top: 50px;
}

.payment-submitted-sub-container {
  display: flex;
  align-items: flex-start;
  padding: 16px;
}

.payment-submitted-title {
  font-style: normal;
  font-size: 18px;
  font-family: "Roboto Bold";
  line-height: 20px;
  color: #424554;
  margin-bottom: 32px;
}

.payment-submitted-icon-panel {
  margin-right: 10px;
}

.payment-submitted-body-redirect {
  margin-top: 32px;
  margin-bottom: 32px;
}
