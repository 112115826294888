.address-results-item {
  padding-left: 8px;
  padding-right: 8px;
}

.address-results-item:hover {
  background: var(--list-item-hover-color);
}

.address-results-item-title {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  padding-left: 8px;
  padding-top: 8px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 700;
  font-family: "Roboto Bold";
  color: var(--color-dark-grey);
  margin: 0;
}

.address-results-item-description {
  padding-left: 8px;
  color: var(--color-medium-grey);
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  padding-bottom: 5px;
}

.address-results-item-divider {
  border-top: 1px solid #e2e8f0;
  opacity: 1;
}

.address-results-item:hover .address-results-item-divider {
  border-top-color: lightblue;
}
