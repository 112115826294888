.dob-container {
  display: flex;
  flex-direction: column;
  min-height: 50dvh;
}

.select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.select-dropdown {
  width: 100%;
  min-height: 48px;
}

.button-container {
  margin-top: auto;
}

.dob-title-container {
  display: flex;
  align-items: center;
}

.dob-icon-container {
  padding-left: 5px;
  padding-top: 5px;
}
