.base-template {
  background-color: #424554;
  display: flex;
  flex-direction: column;
  height: auto;
}

.base-template header {
  background-color: var(--color-white);
  height: 80px;
  padding: 20px 32px;
  padding-left: 60px;
}

.base-template-body {
  display: flex;
  justify-content: space-evenly;
  min-width: 300px;
  margin: 80px 0px;
  height: calc(100dvh - 240px);
  min-height: 660px;
}

@media screen and (min-width: 1375px) {
  .base-template-body {
    gap: 120px;
    justify-content: center;
    margin: 80px 120px;
  }
}

.base-template-body-hidden {
  display: flex;
}

.base-template-main-content {
  width: 640px;
  background-color: var(--color-white);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 40px 72px;
}

.base-template-collapse-button {
  width: 100%;
  height: 48px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  background: none;
  border: none;
  padding: 12px 16px;
  white-space: nowrap;
}

.base-template-collapsible-content {
  height: calc(100dvh - 128px);
  overflow: scroll;
}

.base-template-side-content {
  width: 375px;
}

.base-template-side-content-hidden {
  display: none;
}

.base-template-collapsible-menu {
  display: none;
}

@media screen and (max-width: 1200px) {
  .base-template {
    flex-direction: column;
    height: 100dvh;
    overflow: hidden;
  }
  .base-template-side-content {
    display: none;
  }

  .base-template-collapsible-menu {
    display: block;
  }

  .base-template-body {
    width: 100%;
    margin: 0;
    min-height: unset;
  }

  .base-template-body-hidden {
    display: none;
  }

  .base-template-main-content {
    width: 100%;
    min-height: calc(100dvh - 128px);
    padding: 28px 16px 36px;
  }

  .base-template header {
    padding: 20px 16px;
  }
}
