.loading-spinner {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: icon-rotate;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  background:
    linear-gradient(var(--color-white), var(--color-white)) content-box,
    linear-gradient(to right, var(--color-purple) 0%, var(--color-purple) 65%, var(--color-white) 65%, var(--color-white) 100%) border-box;
  height: 48px;
  width: 48px;
  margin: auto;
  border: 5px solid transparent;
  border-radius: 50%;
}

@keyframes icon-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
