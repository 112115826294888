.search-input-icon {
  color: #0079b3;
  position: absolute;
  top: 20px;
  right: 14px;
  height: 1em;
  width: 1em;
}

.search-input-close-icon {
  color: #0079b3;
  position: absolute;
  top: 20px;
  right: 14px;
  cursor: pointer;
  height: 1em;
  width: 1em;
}
