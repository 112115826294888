.eligibility-loading-container {
  display: flex;
  height: 100%;
}

.eligibility-title-description-inner-bold {
  font-size: 16px;
  font-family: "Roboto bold";
  color: #424554;
}

.eligibility-form-fields-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 36px;
  margin-bottom: 36px;
}

.eligibility-form-sole-proprietorship-field {
  display: inline-block;
  margin-bottom: 16px;
  width: 100%;
  font-family: "Roboto";
}

.eligibility-form-sole-proprietorship-field-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-top: 16px;
}

.eligibility-form-sole-proprietorship-field-selection-yes {
  margin-right: 64px;
}

.eligibility-form-sole-proprietorship-field-selection-no {
  width: 100%;
}

.eligibility-form-fields-first-last-name {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .eligibility-form-fields-first-last-name {
    display: flex;
    flex-direction: column;
  }

  .eligibility-form-fields-first-name {
    width: 100%;
    margin-right: 0px;
  }

  .eligibility-form-fields-last-name {
    width: 100%;
    margin-left: 0px;
    margin-top: 32px;
  }
}
