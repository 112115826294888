.fp-lite-payment-submitted-sub-container {
  max-width: 380px;
  margin-left:auto;
  margin-right:auto;
}

.fp-lite-payment-submitted-redirect {
  margin-top: 32px;
  margin-bottom: 32px;
}

.fp-lite-payment-submitted-title {
  font-size: 20px;
  font-family: "Roboto Bold", sans-serif;
  line-height: 30px;
  text-align: center;
  color: #424554;
  margin-top: 16px;
}

.fp-lite-payment-submitted-separator {
  background-color: lightgray;
  height: 1px;
  border: none;
  margin-top: 64px;
  margin-bottom: 64px;
}

@media only screen and (max-width: 1200px)  { .fp-lite-payment-submitted-separator { margin-top: 32px; margin-bottom: 32px; } }

.fp-lite-payment-submitted-payment-summary-title {
  font-size: 20px;
  font-family: "Roboto Bold", sans-serif;
  line-height: 30px;
  text-align: center;
  color: #424554;
}

.fp-lite-payment-submitted-payment-summary-subtitle {
  font-size: 18px;
  font-family: "Roboto";
  line-height: 30px;
  text-align: center;
  color: #424554;
  margin-top: 8px;
  margin-bottom: 32px;
}

.fp-lite-payment-submitted-repayment-pills-section {
  margin-left: auto;
  margin-right: auto;
}

.base-template-main-content.confetti-background {
  background-image: url("assets/images/confetti.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
}
