.title-section {
  margin-bottom: 16px;
}

.address-section {
  min-height: 42px;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  color: #424554;
  font-family: "Roboto Light", sans-serif;
}

.address-title {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
}

.address-label-title {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-dark-grey)
}

.address-button {
  margin-left: auto;
  border: none;
  background-color: transparent;
  color: #0079b3;
  font-family: "Roboto Bold", sans-serif;
}

.address-button:hover {
  text-decoration-line: underline;
}

.address-separator {
  height: 1px;
  background-color: #e2e8f0;
}
