.direct-debit-content {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.direct-debit-horizontal-grouping {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.direct-debit-description-top-section-main {
  font-size: 16px;
  font-family: "Roboto bold";
  color: #424554;
}

.direct-debit-description-top-section-secondary {
  font-size: 16px;
  font-family: "Roboto";
  color: #424554;
}

.direct-debit-guarantee-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.direct-debit-loading-container {
  display: flex;
  height: 100%;
}
